<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.15" height="13.72" viewBox="0 0 17.15 13.72">
    <path
      id="Icon_material-mail-outline" data-name="Icon material-mail-outline"
      d="M18.435,6H4.715A1.713,1.713,0,0,0,3.009,7.715L3,18.005A1.72,1.72,0,0,0,4.715,19.72h13.72a1.72,1.72,0,0,0,1.715-1.715V7.715A1.72,1.72,0,0,0,18.435,6Zm0,12.005H4.715V9.43l6.86,4.288,6.86-4.288Zm-6.86-6L4.715,7.715h13.72Z"
      transform="translate(-3 -6)" fill="#ed2894"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
