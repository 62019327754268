<script>
import SmallCashIcon from '@/assets/svgs/smallCashIcon.vue'
import smallLock from '@/assets/svgs/smallLock.vue'
export default {
  components: {
    SmallCashIcon,
    SmallLock: smallLock,
  },
  props: ['paymentData'],
}
</script>

<template>
  <div
    class="multistep__step3__paymentMode" :class="{ selected: paymentData.selected }"
    @click="$emit('paymentMode', paymentData.mode)"
  >
    <div class="row">
      <div class="multistep__step3__paymentMode__whitebox" :class="paymentData.whiteboxclass">
        <slot name="paymenticon" />
      </div>
      <span
        class="multistep__step3__paymentMode__bottomContent__text mt-2 ml-2 pl-5"
        v-html="paymentData.titleText"
      />
    </div>
    <br class="d-md-none">
    <div class="multistep__step3__paymentMode__bottomContent d-flex ">
      <div class="multistep__step3__paymentMode__bottomContent__iconbox">
        <component :is="paymentData.icon" />
      </div>
      <p class="multistep__step3__paymentMode__bottomContent__text " v-html="paymentData.text" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
