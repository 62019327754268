<script>
export default {
  computed: {
    currentStepNumber() {
      return this.$store.state.currentStepNumber
    },
  },
}
</script>

<template>
  <div
    v-if="$store.state.currentStepNumber < 4"
    class="d-flex align-items-center mx-auto justify-content-between multistep__stepBars"
  >
    <div class="multistep__stepBars__bar complete" />
    <div class="multistep__stepBars__bar" :class="{ complete: currentStepNumber >= 2 }" />
    <div class="multistep__stepBars__bar" :class="{ complete: currentStepNumber >= 3 }" />
  </div>
</template>

<style lang="scss" scoped>

</style>
