<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26.141" height="11.501" viewBox="0 0 26.141 11.501">
    <path
      id="Path_3311" data-name="Path 3311"
      d="M21.86,6.208a3.569,3.569,0,0,0-.628.233,4.881,4.881,0,0,1-2.655.915,18.514,18.514,0,0,1-1.956-.2v3.7c0,.466-.179.646-.646.646H.564c-.466,0-.646-.179-.664-.646V2.763c0-.484.179-.664.682-.664H8.835a.976.976,0,0,0,.682-.251c.574-.466,1.166-.915,1.722-1.4A2.17,2.17,0,0,1,12.585,0c2.332.018,4.683.018,7.015,0a2.96,2.96,0,0,1,1.561.377c.215.126.449.215.682.323.09-.592.215-.7.807-.7h2.745c.466,0,.646.179.646.646v6.01c0,.466-.179.646-.646.646H22.614c-.592,0-.754-.144-.754-.754ZM.976,10.442h14.6v-4.9a.248.248,0,0,0-.072-.054l-.377.431a1.561,1.561,0,1,1-2.207-2.207c.161-.161.341-.323.574-.538H.976Q.976,6.782.976,10.442ZM21.86,5.257c0-.9-.036-1.722.018-2.548.036-.592-.108-.987-.718-1.166a7.422,7.422,0,0,1-.771-.359,1.591,1.591,0,0,0-.5-.126H12.513a.648.648,0,0,0-.377.072c-.395.287-.789.61-1.166.915.951.072,1.884.054,2.817.072a1.232,1.232,0,0,0,1.13-.395.459.459,0,0,1,.7,0,.513.513,0,0,1,.018.736c-.054.072-.144.144-.2.215-.592.592-1.2,1.166-1.758,1.794a.8.8,0,0,0-.161.574.527.527,0,0,0,.377.323.736.736,0,0,0,.538-.233c.754-.718,1.471-1.453,2.189-2.189a.7.7,0,0,1,.9-.2,2.8,2.8,0,0,0,1.525.233c.359-.054.682-.2,1.041-.287A.512.512,0,0,1,20.748,3a.489.489,0,0,1-.287.664,3.648,3.648,0,0,1-2.566.269.857.857,0,0,0-1.112.359.751.751,0,0,0-.144.377c-.018.341,0,.7-.018,1.041-.018.2.072.269.233.323a3.675,3.675,0,0,0,3.5-.269A1.854,1.854,0,0,1,21.86,5.257Zm3.122,1V1.076H22.937V6.262Z"
      transform="translate(0.1)" fill="#00a1de"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
