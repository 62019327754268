<script>
import AppForm from '@/components/pages/MultiStepForm.vue'
export default {
  components: {
    AppForm,
  },
}
</script>

<template>
  <div>
    <AppForm />
  </div>
</template>

<style lang="scss" scoped>

</style>
