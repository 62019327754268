<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: { BFormSelect },
  props: ['inputData', 'inputContainerExternalClass'],
  data() {
    return {
      selected: null,
    }
  },
  mounted() {
  },

  methods: {
    setInputVal(title) {
      this.$emit('onSelector', { text: title, value: this.selected })
    },
  },
}
</script>

<template>
  <div class="FormInput " :class="inputContainerExternalClass">
    <label :for="inputData.heading" class="FormInput__inputContainer">
      <!-- <p class="FormInput__heading">
        <span>{{ inputData.heading }}</span>
        <span class="text-danger" v-if="inputData.require">*</span>
      </p> -->
      <BFormSelect v-model="selected" :options="inputData.options" @change="setInputVal(inputData.title)" />
    </label>
  </div>
</template>

<style lang="scss" scoped>

</style>
