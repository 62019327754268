<script>
export default {}
</script>

<template>
  <svg
    id="Group_1008" data-name="Group 1008" xmlns="http://www.w3.org/2000/svg" width="65.731" height="28.918"
    viewBox="0 0 65.731 28.918"
  >
    <path
      id="Path_3311" data-name="Path 3311"
      d="M55.119,15.609a8.974,8.974,0,0,0-1.579.586,12.272,12.272,0,0,1-6.677,2.3,46.553,46.553,0,0,1-4.917-.5v9.293c0,1.173-.451,1.624-1.624,1.624H1.569C.4,28.918-.055,28.467-.1,27.294V6.948c0-1.218.451-1.669,1.714-1.669H22.367a2.455,2.455,0,0,0,1.714-.632c1.444-1.173,2.932-2.3,4.331-3.519A5.457,5.457,0,0,1,31.8,0C37.66.045,43.57.045,49.435,0A7.443,7.443,0,0,1,53.36.947c.541.316,1.128.541,1.714.812C55.3.271,55.615,0,57.1,0h6.9c1.173,0,1.624.451,1.624,1.624V16.737c0,1.173-.451,1.624-1.624,1.624H57.014c-1.489,0-1.895-.361-1.895-1.895ZM2.607,26.256H39.329V13.94a.623.623,0,0,0-.18-.135L38.2,14.888a3.924,3.924,0,1,1-5.549-5.549c.406-.406.857-.812,1.444-1.353H2.607Q2.607,17.053,2.607,26.256ZM55.119,13.218c0-2.256-.09-4.331.045-6.406.09-1.489-.271-2.481-1.8-2.932a18.662,18.662,0,0,1-1.94-.9,4,4,0,0,0-1.263-.316H31.615a1.631,1.631,0,0,0-.947.18c-.992.722-1.985,1.534-2.932,2.3,2.391.18,4.737.135,7.083.18,1.083,0,2.075,0,2.842-.993a1.155,1.155,0,0,1,1.759,0,1.29,1.29,0,0,1,.045,1.85c-.135.18-.361.361-.5.541-1.489,1.489-3.023,2.932-4.421,4.511a2.023,2.023,0,0,0-.406,1.444,1.326,1.326,0,0,0,.947.812,1.85,1.85,0,0,0,1.353-.586c1.895-1.8,3.7-3.654,5.5-5.5A1.762,1.762,0,0,1,44.2,6.9a7.035,7.035,0,0,0,3.835.586c.9-.135,1.714-.5,2.617-.722a1.287,1.287,0,0,1,1.669.767A1.229,1.229,0,0,1,51.6,9.2a9.174,9.174,0,0,1-6.451.677,2.156,2.156,0,0,0-2.8.9,1.889,1.889,0,0,0-.361.947c-.045.857,0,1.759-.045,2.617-.045.5.18.677.586.812a9.241,9.241,0,0,0,8.8-.677C52.5,13.715,53.54,12.857,55.119,13.218Zm7.85,2.526V2.707H57.826V15.745Z"
      transform="translate(0.1 0)" fill="#0067b3"
    />
    <path
      id="Path_3312" data-name="Path 3312"
      d="M39.333,37.566a5.233,5.233,0,1,1,5.233-5.233A5.237,5.237,0,0,1,39.333,37.566Zm0-7.714a2.526,2.526,0,1,0,2.526,2.526A2.522,2.522,0,0,0,39.333,29.852Z"
      transform="translate(-18.671 -14.874)" fill="#0067b3"
    />
    <path
      id="Path_3313" data-name="Path 3313"
      d="M23.438,38.488h-.361a.694.694,0,0,1-.677-.677V33.977a.694.694,0,0,1,.677-.677h.361a.694.694,0,0,1,.677.677v3.835A.693.693,0,0,1,23.438,38.488Z"
      transform="translate(-12.249 -18.277)" fill="#0067b3"
    />
    <path
      id="Path_3314" data-name="Path 3314"
      d="M66.238,38.188h-.361a.694.694,0,0,1-.677-.677V33.677A.694.694,0,0,1,65.877,33h.361a.694.694,0,0,1,.677.677v3.835A.694.694,0,0,1,66.238,38.188Z"
      transform="translate(-35.741 -18.112)" fill="#0067b3"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
