<script>
export default {
  props: ['inputData', 'inputContainerExternalClass'],
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    inputData: {
      handler(input) {
        this.inputValue = input.initialValue
        this.setInputVal(input.title)
      },
      deep: true,
    },
  },

  methods: {
    setInputVal(title) {
      this.$emit('getInputVal', { title, value: this.inputValue })
    },
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="inputData.rules">
    <div class="FormInput " :class="inputContainerExternalClass">
      <label :for="inputData.heading" class="FormInput__inputContainer">
        <p class="FormInput__heading">
          <span>{{ inputData.heading }}</span>
          <span v-if="inputData.require" class="text-danger">*</span>
        </p>
        <input
          :id="inputData.heading" v-model="inputValue" class="FormInput__input" :type="inputData.type"
          :state="errors[0] ? false : valid ? true : null" @blur="setInputVal(inputData.title)"
        >
        <p class="text-secondary FormInput__input__error">{{ errors[0] }}</p>
      </label>
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>

</style>
