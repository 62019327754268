<script>
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/ui/multistepInput.vue'
import TextArea from '@/components/ui/TextArea.vue'
import Button from '@/components/ui/Button.vue'
import Select from '@/components/ui/multistepSelect.vue'
export default {
  components: { Input, TextArea, Button, Select, ValidationObserver },
  data() {
    return {
      heading: 'Informations de livraison',
      submitted: false,
      showspinner: false,
      inputs: [
        {
          require: true,
          heading: 'Prénom',
          type: 'text',
          component: 'Input',
          title: 'firstName',
          from: 'multistep',
          initialValue: '',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Nom',
          title: 'lastName',
          type: 'text',
          component: 'Input',
          from: 'multistep',
          initialValue: '',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Addresse',
          type: 'text',
          component: 'Input',
          title: 'street',
          from: 'multistep',
          initialValue: '',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Region',
          title: 'region',
          type: 'select',
          component: 'Select',
          from: 'multistep',
          options: this.$store.state.regions,
          initialValue: '',
          rules: 'required',
        },
        {
          require: true,
          heading: 'Ville',
          title: 'city',
          type: 'select',
          component: 'Select',
          from: 'multistep',
          options: this.$store.state.cities,
          initialValue: '',
          rules: 'required',
        },
        {
          require: true,
          rules: 'required',
          heading: 'Zone',
          title: 'zone',
          type: 'select',
          component: 'Select',
          from: 'page1',
          options: [
            {
              value: null,
              text: 'Select Zone',
            },
          ],
        },
        {
          require: true,
          heading: 'Téléphone',
          type: 'text',
          title: 'telephone',
          component: 'Input',
          from: 'multistep',
          initialValue: '',
          rules: 'required|numeric',
        },
      ],
      errorText: ' Ce champ est requis.',
      bottomText: 'Commentaire à l’intention du livreur',
      url: 'regions',
      textareaVal: '',
      form: {
        firstName: '',
        lastName: '',
        street: '',
        region: '',
        city: '',
        telephone: '',
        zone: '',
        comment: '',
      },
    }
  },
  computed: {
    Regions() {
      return this.$store.state.regions
    },
    User() {
      return this.$store.state.user
    },
  },
  watch: {
    '$store.state.regions': function (regions) {
      const regionOptions = regions.map((region) => {
        return { value: region.id, text: region.name }
      })

      regionOptions.unshift({
        value: null,
        text: 'Select Region',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title == 'region')
          input.options = regionOptions

        return input
      })
    },
    '$store.state.cities': function (cities) {
      const cityOptions = cities.map((city) => {
        return { value: city.id, text: city.name }
      })

      cityOptions.unshift({
        value: null,
        text: 'Select City',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title == 'city')
          input.options = cityOptions

        return input
      })
    },
    '$store.state.zones': function (zones) {
      const zoneOptions = zones.map((zone) => {
        return { value: zone.id, text: zone.name }
      })
      zoneOptions.unshift({
        value: null,
        text: 'Select Zone',
      })
      this.inputs = this.inputs.map((input) => {
        if (input.title == 'zone')
          input.options = zoneOptions

        return input
      })
    },
  },
  async created() {
    this.$store.dispatch('setRegion', this.url)

    if (this.$cookie.get('saisstoken')) {
      const user = await this.$store.dispatch('getUser', { url: 'auth/me' })
      this.inputs = this.inputs.filter((input) => {
        if (user.hasOwnProperty(input.title)) {
          // input.initialValue = user[input.title];

          this.$set(input, 'initialValue', user[input.title]) // good for reactivity
        }
        return input
      })
    }
  },
  methods: {
    saveData() {
      this.showspinner = true
      const data = {
        name: 'Step3',
        number: 3,
        heading: 'Mode de paiement',
      }

      this.saveInformationToDatabase(data)
    },
    async saveInformationToDatabase(data) {
      const status = await this.$store.dispatch('updateStep2Data', {
        url: 'customers',
        form: this.form,
      })
      if (status) {
        this.$store.commit('setstep2Data', this.form)
        this.showspinner = false
        this.$store.commit('changeStep', data)
      }
    },
    getInputValue(input) {
      this.form[input.title] = input.value
    },
    saveComment(input) {
      this.form[input.title] = input.value
    },
  },
}
</script>

<template>
  <div class="multistep__step2">
    <div class="multistep__step2__container">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <h1 class="text-center multistep__step2__heading text-uppercase">
          {{ heading }}
        </h1>
        <div class="row multistep__step2__inputRow">
          <div
            v-for="input in inputs" :key="input.heading"
            class="col-12 col-md-6 multistep__step2__columnClass"
          >
            <component :is="input.component" :input-data="input" @getInputVal="getInputValue" />
          </div>
          <div class="col-12 col-md-6 multistep__step2__columnClass">
            <p class="Form__required" :class="{ show: submitted }">
              {{ errorText }}
            </p>
          </div>
        </div>
        <p class="multistep__step2__bottomText">
          {{ bottomText }}
        </p>
        <TextArea external-class="multistep__step2__textArea" @getTextAreaValue="saveComment" />
        <div class="text-center">
          <Button
            external-class="multistep__step2__btn text-uppercase" btn-text="Valider mes informations"
            :showspinner="showspinner" :disabled="showspinner" @onClick="handleSubmit(saveData)"
          />
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
