<script>
import { BFormCheckbox } from 'bootstrap-vue'
import PaymentMode from '@/components/pages/MultiStepForm/step3/PaymentMode.vue'
import cashBlueIcon from '@/assets/svgs/cashBlueIcon.vue'
import Button from '@/components/ui/Button.vue'
import Select from '@/components/ui/step3Select.vue'

export default {
  components: {
    PaymentMode,
    CashBlueIcon: cashBlueIcon,
    Button,
    Select,
    BFormCheckbox,
  },
  data() {
    return {
      period: {
        period: 0,
      },
      agree: false,
      periodic: 0,
      message: '',
      showspinner: false,

      cmi: require('../../../assets/images/common/cmi.png'),
      visa: require('../../../assets/images/common/visa.png'),
      mastercard: require('../../../assets/images/common/mastercard.png'),
      heading: 'Adresse de livraison',
      heading2: 'Sélectionnez un mode paiement',
      name: 'Mr Azawila',
      address: '8 rue Ali Yaata . 75010 Casablanca',
      payments: [
        {
          icon: 'smallLock',
          titleText: '<b>Paiement en ligne</b>',
          text: 'Paiement par carte bancaire (facile, sécurisé et permet d’éviter tout contacts avec de la monnaie ou des billets)',
          image: true,
          whiteboxclass: 'whitebox1',
          mode: 'card',
          selected: false,
        },
        {
          icon: 'SmallCashIcon',
          titleText: '<b>Paiement à la livraison</b>',
          text: 'Paiement à la livraison en espèce ou par carte bancaire (en respectant le protocole sanitaire)',
          image: false,
          paymentModeIcon: 'cashBlueIcon',
          whiteboxclass: 'whitebox2',
          mode: 'cash',
          selected: false,
        },
      ],
      error: '',
      showCardForm: false,
      paymentForm: {
        clientid: '600002672',
        storetype: '3D_PAY_HOSTING',
        trantype: 'PreAuth',
        currency: '504',
        hashAlgorithm: 'ver3',
        encoding: 'UTF-8',
        rnd: 'asdf',
        lang: 'fr',
        sessiontimeout: '600',
        okUrl: `${window.location.origin}/success`,
        failUrl: `${window.location.origin}/`,
        callbackUrl: `${window.location.origin}/api/payment/callback`,
        shopurl: `${window.location.origin}/`,
        amount: '60.5', // total amount,
        BillToName: 'Client Sotherma', // client name
        tel: '0699237845', // phonenumber
        email: 'client@sotherma.com', // client email
        oid: '1', // order id
        hash: '',
      },
      mode: '',

      selector: {
        require: true,
        heading: '',
        title: 'periodic',
        options: [
          {
            value: 0,
            text: 'Choisir votre fréquence de livraison',
          },
          {
            text: 'hebdomadaire', // weekly
            value: '1',
          },
          {
            text: 'bi-mensuelle', // by-monthly
            value: '2',
          },
          {
            text: 'mensuelle', // monthly
            value: '3',
          },
        ],
        initialValue: '0',
      },
    }
  },

  methods: {
    showVal(val) {
      this.periodic = val.value
    },
    selectedMode(mode) {
      this.mode = mode
      if (mode === 'card') {
        this.showCardForm = true
        this.payments[0].selected = true
        this.payments[1].selected = false
      }
      else if (mode === 'cash') {
        this.showCardForm = false
        this.payments[0].selected = false
        this.payments[1].selected = true
      }
      this.$store.commit('storeFinalData', mode)
    },
    goBack() {
      const data = {
        name: 'Step2',
        number: 2,
        heading: 'Adresse de livraison',
      }
      this.$store.commit('changeStep', data)
    },
    async NextStep() {
      this.showspinner = true
      if (!this.agree || this.mode.trim().length < 2) {
        this.message = 'sélectionner le mode de paiement'
        this.showspinner = false
        return
      }
      this.message = ''
      if (this.mode === 'card') {
        const res = await this.$store.dispatch('placeOrder', {
          url: 'orders',
          paymentinfo: this.paymentForm,
        })

        if (res.paymentForm) {
          this.paymentForm.amount = res.paymentForm.amount
          this.paymentForm.BillToName = res.paymentForm.BillToName
          this.paymentForm.tel = res.paymentForm.tel
          this.paymentForm.email = res.paymentForm.email
          this.paymentForm.oid = res.paymentForm.oid
          this.paymentForm.hash = res.paymentForm.hash
          this.paymentForm.okUrl = res.paymentForm.okUrl
          this.$refs.formpaiement.clientid.value = '600002672'
          this.$refs.formpaiement.storetype.value = '3D_PAY_HOSTING'
          this.$refs.formpaiement.trantype.value = 'PreAuth'
          this.$refs.formpaiement.currency.value = '504'
          this.$refs.formpaiement.hashAlgorithm.value = 'ver3'
          this.$refs.formpaiement.encoding.value = 'UTF-8'
          this.$refs.formpaiement.rnd.value = 'asdf'
          this.$refs.formpaiement.lang.value = 'fr'
          this.$refs.formpaiement.sessiontimeout.value = '600'
          this.$refs.formpaiement.okUrl.value = this.paymentForm.okUrl
          this.$refs.formpaiement.failUrl.value = `${window.location.origin}/`
          this.$refs.formpaiement.callbackUrl.value = `${window.location.origin}/api/payment/callback`
          this.$refs.formpaiement.shopurl.value = `${window.location.origin}/`

          this.$refs.formpaiement.amount.value
                        = this.paymentForm.amount.toString()

          this.$refs.formpaiement.BillToName.value
                        = this.paymentForm.BillToName.toString()
          this.$refs.formpaiement.tel.value
                        = this.paymentForm.tel.toString()
          this.$refs.formpaiement.email.value
                        = this.paymentForm.email.toString()
          this.$refs.formpaiement.oid.value
                        = this.paymentForm.oid.toString()
          this.$refs.formpaiement.hash.value
                        = this.paymentForm.hash.toString()
          // console.log(this.$refs.formpaiement)
          this.$refs.formpaiement.submit()
          this.showspinner = false
        }
      }
      else if (this.mode === 'cash') {
        const res = await this.$store.dispatch('placeOrder', {
          url: 'orders',
          isPeriodic: this.periodic,
        })

        if (res.orderId) {
          this.showspinner = false
          this.$router.push(`/success?order=${res.orderId}`)
        }
      }
    },
  },
}
</script>

<template>
  <div class="multistep__step3">
    <h1 class="multistep__step3__heading">
      {{ heading }}
    </h1>

    <div
      class="
        multistep__step3__infobox
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="d-flex">
        <div class="multistep__step3__infobox__circle" />
        <div class="multistep__step3__infobox__personalInfoContainer">
          <h1 class="multistep__step3__infobox__name">
            Mr {{ $store.state.user.firstName }}
          </h1>
          <p class="multistep__step3__infobox__address">
            {{ $store.state.customer.addresses[0].street }},
            {{ $store.state.customer.addresses[0].city.name }}
          </p>
        </div>
      </div>
      <Button class="btn" @click="goBack">
        <a href="" class="multistep__step3__infobox__modify">MODIFIER</a>
      </Button>
    </div>
    <!-- checkbox -->
    <!-- <div class="mt-3 text-sm-right multistep__step3__periodicCb">
      <b-form-checkbox v-model="periodic">
        <span class="text-center multistep__step3__agree my-2">
          Rendre cette commande périodique
        </span>
      </b-form-checkbox>
    </div>

    <div v-if="periodic">
      <Select
        inputContainerExternalClass="multistep__step3__selector"
        :inputData="selector"
        @onSelector="showVal"
      />
    </div> -->

    <h1 class="multistep__step3__heading2">
      {{ heading2 }}
    </h1>
    <PaymentMode
      v-for="paymentData in payments" :key="paymentData.id" :payment-data="paymentData"
      @paymentMode="selectedMode"
    >
      <template #paymenticon>
        <div
          v-if="paymentData.image" class="
            d-flex
            justify-content-center
            multistep__step3__paymentImagesContainer
          "
        >
          <img class="img-fluid multistep__step3__paymentImagesContainer__img img1" :src="cmi" alt="">
          <img
            class="
              mx-2
              img-fluid
              multistep__step3__paymentImagesContainer__img
              img2
            " :src="visa" alt=""
          >
          <img
            class="img-fluid multistep__step3__paymentImagesContainer__img img3" :src="mastercard"
            alt=""
          >
        </div>
        <div
          v-if="!paymentData.image" class="
            d-flex
            justify-content-center
            multistep__step3__paymentModeIconContainer
          "
        >
          <component :is="paymentData.paymentModeIcon" />
        </div>
      </template>
    </PaymentMode>

    <div class="multistep__step3__cbContainer mx-auto">
      <BFormCheckbox v-model="agree">
        <span class="text-center multistep__step3__agree my-2">
          J'accepte les

          <a href="/terms" target="_blank" class="multistep__termslink text-secondary">conditions générales de
            vente.</a></span>
      </BFormCheckbox>
    </div>

    <div v-if="showCardForm">
      <form ref="formpaiement" name="formpaiement" method="POST" action="https://payment.cmi.co.ma/fim/est3Dgate">
        <input type="hidden" name="clientid" value="600002594">
        <input type="hidden" name="storetype" value="3D_PAY_HOSTING">
        <input type="hidden" name="trantype" value="PreAuth">
        <input type="hidden" name="currency" value="504">
        <input type="hidden" name="hashAlgorithm" value="ver3">
        <input type="hidden" name="encoding" value="UTF-8">
        <input type="hidden" name="rnd" value="asdf">

        <input type="hidden" name="lang" value="fr">
        <input type="hidden" name="sessiontimeout" value="">
        <input type="hidden" name="okUrl" value="">
        <input type="hidden" name="failUrl" value="">
        <input type="hidden" name="callbackUrl" value="">
        <input type="hidden" name="shopurl" value="">
        <input type="hidden" name="amount" value="">
        <input type="hidden" name="BillToName" value="">
        <input type="hidden" name="tel" value="">
        <input type="hidden" name="email" value="">
        <input type="hidden" name="oid" value="">
        <input id="cmiHash" type="hidden" name="hash" value="">
      </form>
    </div>
    <p v-if="!agree" class="multistep__step3__error my-2 text-center">
      Veuillez accepter les
      <a href="/terms" target="_blank" class="multistep__termslink text-secondary">conditions générales de
        vente.</a>
    </p>

    <div class="text-center">
      <p v-if="!agree || mode.length < 2" class="text-secondary page1__required">
        {{ message }}
      </p>
      <Button
        external-class="multistep__step3__btn text-uppercase" btn-text="Commander" :showspinner="showspinner"
        :disabled="showspinner" @onClick="NextStep"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
