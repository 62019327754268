<script>
export default {}
</script>

<template>
  <svg id="XMLID_1_" xmlns="http://www.w3.org/2000/svg" width="12.061" height="17.305" viewBox="0 0 12.061 17.305">
    <path
      id="XMLID_3_"
      d="M10.488,7.216V4.457a4.457,4.457,0,1,0-8.915,0V7.216a6.031,6.031,0,1,0,8.915,0ZM6.031,1.573A2.889,2.889,0,0,1,8.915,4.457V5.978a6.032,6.032,0,0,0-5.768,0V4.457A2.889,2.889,0,0,1,6.031,1.573Zm0,14.159a4.457,4.457,0,1,1,4.457-4.457A4.46,4.46,0,0,1,6.031,15.732Z"
      fill="#00a1de"
    />
    <path
      id="XMLID_2_"
      d="M91.311,175a1.311,1.311,0,0,0-.787,2.36v1.049a.787.787,0,1,0,1.573,0V177.36a1.311,1.311,0,0,0-.787-2.36Z"
      transform="translate(-85.28 -165.823)" fill="#00a1de"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
