<script>
export default {
  props: ['inputData'],
  data() {
    return {
      value: '',
    }
  },
}
</script>

<template>
  <ValidationProvider v-slot="{ errors, valid }" :rules="inputData.rules">
    <div class="FormInput__iconinputContainer" :class="inputData.class">
      <label :id="inputData.id" class="FormInput__iconinputContainer__label" :class="inputData.class2">
        <slot name="inputicon" />
        <input
          :id="inputData.id" v-model="value" class="FormInput__input iconInput" :type="inputData.type"
          :placeholder="inputData.placeholder" :class="inputData.inputClass"
          :state="errors[0] ? false : valid ? true : null"
          @blur="$emit('inputvalue', { type: inputData.name, value })"
        >
        <slot name="inputiconright" />
      </label>
      <p class="text-secondary FormInput__input__error">
        {{ errors[0] }}
      </p>
    </div>
  </ValidationProvider>
</template>

<style lang="scss" scoped>

</style>
