<script>
import { ValidationObserver } from 'vee-validate'
import Input from '@/components/ui/IconInput.vue'
import MailIcon from '@/assets/svgs/Mail.vue'
import LockIcon from '@/assets/svgs/PasswordLock.vue'
import Button from '@/components/ui/Button.vue'
export default {
  components: {
    ValidationObserver,
    MailIcon,
    LockIcon,
    Input,
    Button,
  },
  data() {
    return {
      heading: 'Vous avez déjà un compte ?',
      bottomText1: 'Nouveau client ?',
      bottomText2: 'Créez votre compte client sur notre site',
      showspinner: false,
      inputData: [
        {
          id: '1xdcf12',
          type: 'email',
          placeholder: 'Email',
          icon: 'MailIcon',
          class: 'multistep__step1__input1',
          inputClass: 'multistep__step1__iconInput',
          name: 'email',
          rules: 'required|email',
        },
        {
          id: '1xdcf13',
          type: 'password',
          placeholder: 'Mot de passe',
          icon: 'LockIcon',
          class: 'multistep__step1__input2',
          inputClass: 'multistep__step1__iconInput',
          name: 'password',
          rules: 'required',
        },
      ],
      email: '',
      password: '',
      forgotPass: 'Mot de passe oublié ?',
    }
  },

  computed: {
    authenticated() {
      return this.$store.state.authenticated
    },
    message() {
      return this.$store.state.status
    },
    cartTotalPrice() {
      return this.$store.state.cart.total
    },
    cartQuantity() {
      return this.$store.state.cart.cartQuantity
    },
  },
  methods: {
    getInputValues(input) {
      if (input.type === 'email')
        this.email = input.value
      else if (input.type === 'password')
        this.password = input.value
    },
    // changeStep() {},
    async login() {
      this.$store.commit('clearOrderData')
      this.showspinner = true
      const token = await this.$store.dispatch('login', {
        url: 'auth/signin',
        data: { email: this.email, password: this.password },
      })

      if (token && this.cartQuantity > 0 && this.cartTotalPrice >= 150) {
        const data = {
          name: 'Step2',
          number: 2,
          heading: 'Adresse de livraison',
        }
        this.showspinner = false
        this.$store.commit('changeStep', data)
        return
      }
      if (token) {
        this.showspinner = false
        this.$router.push('/orders')
        return
      }
      this.showspinner = false
    },
  },
}
</script>

<template>
  <div class="multistep__step1 d-flex justify-content-center">
    <div class="multistep__step1__container">
      <h1 class="multistep__step1__heading">
        {{ heading }}
      </h1>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <Input v-for="data in inputData" :key="data.id" :input-data="data" @inputvalue="getInputValues">
          <template #inputicon>
            <div class="FormInput__iconinputContainer__label__icon">
              <component :is="data.icon" />
            </div>
          </template>
        </Input>
        <!-- <p class="multistep__step1__forgotPass text-center text-md-left">
          {{ forgotPass }}
        </p> -->
        <p v-if="message" class="multistep__step1__forgotPass text-center text-md-left">
          {{ message }}
        </p>
        <div class="text-center">
          <Button
            external-class="multistep__step1__btn text-uppercase" btn-text="Se connecter"
            :showspinner="showspinner" @onClick="handleSubmit(login)"
          />
        </div>
      </ValidationObserver>
      <div class="multistep__step1__hr" />

      <div class="multistep__step1__bottomTextContainer">
        <h1 class="text-uppercase multistep__step1__bottomText1">
          {{ bottomText1 }}
        </h1>
        <h1 class="text-uppercase multistep__step1__bottomText2">
          {{ bottomText2 }}
        </h1>
      </div>
      <div class="text-center  d-block multistep__step1__bottomBtnContainer">
        <Button
          external-class="multistep__step1__btn btn-secondary text-uppercase multistep__step1__bottomBtn"
          btn-text="Créer un compte" @onClick="$router.push({ name: 'addcustomer' })"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
