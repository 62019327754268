<script>
import Step1 from './MultiStepForm/step1.vue'
import Step2 from './MultiStepForm/step2.vue'
import Step3 from './MultiStepForm/step3.vue'
import Step4 from './MultiStepForm/step4.vue'
import Bars from './MultiStepForm/Bars.vue'
export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Bars,
  },
  data() {
    return {}
  },
  computed: {
    currentStep() {
      return this.$store.state.currentStep
    },

    currentHeading() {
      return this.$store.state.currentHeading
    },
  },
  created() {
    if (this.$cookie.get('saisstoken')) {
      const data = {
        name: 'Step2',
        number: 2,
        heading: 'Adresse de livraison',
      }
      this.$store.commit('changeStep', data)
    }
    else if (!this.$cookie.get('saisstoken')) {
      this.$store.commit('resetSteps')
    }
  },
}
</script>

<template>
  <div class="page2__headerContainer">
    <div class="multistep  d-flex justify-content-center">
      <div>
        <h1
          v-if="$store.state.currentStepNumber < 4"
          class="multistep__heading text-center text-uppercase mx-auto"
        >
          {{ currentHeading }}
        </h1>
        <Bars />
        <component :is="currentStep" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
