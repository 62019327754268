<script>
import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: { BFormTextarea },
  props: ['externalClass'],
  data() {
    return {
      value: '',
    }
  },
  methods: {
    getTextAreaValue() {
      this.$emit('getTextAreaValue', {
        title: 'comment',
        value: this.value,
      })
    },
  },
}
</script>

<template>
  <BFormTextarea
    v-model="value" class="FormInput__textarea" :class="externalClass" placeholder="" rows="3"
    no-resize @blur="getTextAreaValue"
  />
</template>
