<script>
export default {}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.714" height="18" viewBox="0 0 13.714 18">
    <path
      id="Icon_material-lock-outline" data-name="Icon material-lock-outline"
      d="M12.857,15.214A1.714,1.714,0,1,0,11.143,13.5,1.719,1.719,0,0,0,12.857,15.214ZM18,7.5h-.857V5.786a4.286,4.286,0,0,0-8.571,0V7.5H7.714A1.719,1.719,0,0,0,6,9.214v8.571A1.719,1.719,0,0,0,7.714,19.5H18a1.719,1.719,0,0,0,1.714-1.714V9.214A1.719,1.719,0,0,0,18,7.5ZM10.2,5.786a2.657,2.657,0,0,1,5.314,0V7.5H10.2Zm7.8,12H7.714V9.214H18Z"
      transform="translate(-6 -1.5)" fill="#ed2894"
    />
  </svg>
</template>

<style lang="scss" scoped>

</style>
