<script>
import PlayIcon from '@/assets/svgs/Play.vue'
export default {
  components: { PlayIcon },
  data() {
    return {
      text: '',
      retourText: 'retour',
    }
  },
  computed: {
    orderno() {
      // console.log(this.$store.state.orderNumber);
      return this.$store.state.orderNumber
    },
  },
  // watch: {
  //   orderno(value) {
  //     this.text =
  //       "Commande Numéro <span class='alternate'>" +
  //       value +
  //       "</span> ";
  //     console.log(this.text);
  //   },
  // },
}
</script>

<template>
  <div class="multistep__step4 d-flex justify-content-center">
    <div>
      <div class="multistep__step4__box">
        <p class="multistep__step4__box__text">
          Commande Numéro <span class="alternate">{{ orderno }}</span> finalisée
        </p>
      </div>

      <button
        class="btn mx-auto multistep__step4__retour d-flex justify-content-center align-items-center"
        @click="$router.back()"
      >
        <PlayIcon />
        <p class="multistep__step4__retour__text text-uppercase">
          {{ retourText }}
        </p>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
